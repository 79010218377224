import React from 'react'
import Layout from '../../components/Layout'
import Hero from '../../components/Hero'
import SEO from "../../components/seo"
import { Link } from 'gatsby'
import CTA from "../../components/cta"
import Customer from '../../components/CustomerList'

export default () => (
  <Layout>

    <SEO title="Markaðstorg fyrir ferðaþjónustu"
        description=""
        pathname="/product/"
    />
        
    <Hero 
         headerText="Markaðstorg" 
         subHeaderText="Séríslenskt markaðstorg fyrir ferðaþjónustu á Íslandi"
            /> 

    <section className="section column is-10 is-offset-1 content-pages">
      <div className="container">
        <div className="content center intro">
            <h2>Hvetjum til ferðalaga innanlands</h2>
            
            <p>Við hvetjum öll fyrirtæki á Íslandi sem eru í ferðaþjónustu að taka þátt í þessu verkefni.</p>

            <CTA />
        </div>
      </div>
    </section>



    <section className="section column is-10 is-offset-1 content content-pages">

        <h2 className="center title is-size-4">Hvernig mun markaðstorgið virka?</h2>
        <p className="center">Nýtt verður núverandi tækni sem er þegar í notkun hjá flestum fyrirtækjum í ferðaþjónustu í dag. Með þessu má lágmarka vinnu og kostnað við uppsetningu. Sett verður upp sérstök leitarvél þar sem hægt er að leita að þjónustu eftir flokkum og landsvæðum.</p>
        <div className="columns is-multiline">
            <div className="column is-6">
                <section className="section">
                    <h3 className="has-text-weight-semibold">Ferðir og afþreying</h3>
                    <p className="">Flest fyrirtæki í ferðum og afþreyingu nota bókunarkerfið frá Bókun. Gera þarf endursölusamning í Bókun sem ber þá lægri söluþóknun og lægri verð. </p>
                </section>
            </div>
            <div className="column is-6">
                <section className="section">
                    <h3 className="has-text-weight-semibold">Gisting</h3>
                    <p className="">Skoðað verður samstarf við helstu fyrirtæki sem bjóða bókunarvélar fyrir gististaði. </p>                   
                </section>
            </div>
            <div className="column is-6">
                <section className="section">
                    <h3 className="has-text-weight-semibold">Veitingastaðir</h3>
                    <p className="">Ekki er ráðgert að setja upp sérstakt pöntunarkerfi fyrir veitingastaði.  Leitast verður þó við að setja upp sérstakt kynnningarsvæði/leitarvél.</p>
                </section>
            </div>
            <div className="column is-6">
                <section className="section">
                    <h3 className="has-text-weight-semibold">Söfn og sýningar</h3>
                    <p className="">Ekki er ráðgert að setja upp sérstakt pöntunarkerfi fyrir söfn og sýningar. Leitast verður þó við að setja upp sérstakt kynnningarsvæði/leitarvél.</p>
                </section>
            </div>
        </div>

    </section>

    <section id="partners">
        <div  className="content intro center customers">
        <h3>Hverjir taka þátt?</h3>
        <p>Fjöldi fyrirtækja hefur lýst yfir samstöðu og áhuga á því að taka þátt í þessu verkefni.</p>
        
        <div className="columns is-multiline is-vcentered">
          
          <Customer imgSrc="/img/elding.png" altText="Elding" website="https://elding.is" />
          <Customer imgSrc="/img/grayline.png" altText="Gray Line" website="https://grayline.is" />
          <Customer imgSrc="/img/rss.png" altText="Reykjavik Sightseeing" website="https://rss.is" />
          <Customer imgSrc="/img/solhestar.png" altText="Sólhestar" website="https://solhestar.is" />
          <Customer imgSrc="/img/whale-safari.png" altText="Whale Safari" website="https://whalesafari.is" />  
          <Customer imgSrc="/img/mrpuffin.jpg" altText="Mr Puffin" website="https://puffintours.is" />
          <Customer imgSrc="/img/airportdirectlogo.jpg" altText="Airport Direct" website="https://airportdirect.is" />
          <Customer imgSrc="/img/happytours.png" altText="Happy Tours" website="https://happytours.is" />
          <Customer imgSrc="/img/whaleakureyri.png" altText="Akureyri Whale Watching" website="https://www.whalewatchingakureyri.is/" />  

          <Customer imgSrc="/img/dynjandi.png" altText="Dynjandi" website="http://www.dynjandi.com" />  
          <Customer imgSrc="/img/skalafell.png" altText="Skálafell Guesthouse" website="https://www.skalafell.net" />  
          <Customer imgSrc="/img/localguide.png" altText="Local Guide Vatnajökull" website="localguide.is" />  
          <Customer imgSrc="/img/icecaveiceland.png" altText="Ice Cave Iceland" website="https://www.icecaveiniceland.is/" />  
          <Customer imgSrc="/img/blackbeach.png" altText="Black Beach Tours" website="https://blackbeachtours.is/" />  
          <Customer imgSrc="/img/selasetur.jpeg" altText="Selasetur Íslands" website="http://www.selasetur.is" />  

         </div> 
         
         <CTA />

         </div>
    </section>
  </Layout>
)
